<template>
    <div class="right-side">
        <h2>{{$t("AccountPage.AccountInvitations.title")}}</h2>
        <div class="mb-4">{{$t("AccountPage.AccountInvitations.description")}}</div>

        <form @submit.prevent="onSubmit()">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-at"></i></span>
                </div>
                <input type="text" class="form-control form-control-lg" id="emailInput" :placeholder="$t('invitations.edit_email')" v-model="form.invitedEmail">
                <div class="input-group-append">
                    <button class="btn btn-lg btn-zhako"> {{ $t("change_password.send") }} </button>
                </div>
            </div>
            <template v-if="$v.form.invitedEmail.$error">
            <small class="form-text text-danger" v-if="!$v.form.invitedEmail.required">{{ $t('CommonForm.field_is_required') }}</small>
            <small class="form-text text-danger" v-if="!$v.form.invitedEmail.email">{{ $t('CommonForm.enter_your_email') }}</small>
            </template>
        </form>

        <h5 class="mt-5">{{ $t("invitations.outcome") }}</h5>
        <table class="table table-bordered">
        <thead>
            <tr>
                <th>
                    {{ $t("invitations.email") }}
                </th>
                <th>
                    {{ $t("invitations.status") }}
                </th>
                <th>
                    {{ $t("invitations.userFullname") }}
                </th>
                <th>

                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in data.outcome" :key="item.id">
                <td>{{item.invitedEmail}}</td>
                <td>{{item.status == 0 ? translate('Не подтверждено','Расталмады'):
                    translate('Подтверждено','Расталды')}}
                </td>
                <td>
                    {{item.invitedUser && item.invitedUser.userDetail.surname}} 
                    {{item.invitedUser && item.invitedUser.userDetail.firstname}} 
                </td>
                <td>
                    <button class="btn btn-danger" @click="remove(item.id)">
                        <i class="fas fa-trash"></i>
                    </button>
                </td>
            </tr>
        </tbody>
        </table>
        <paginate
            v-if="outcomePageCount"
            v-model="outcomePage"
            :page-count="outcomePageCount"
            :click-handler="changeOutcomePage"
            :prev-text="'&laquo;'"
            :next-text="'&raquo;'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
            ></paginate>

        
        <h5 class="mt-5"> {{ $t("invitations.income") }} </h5>
        <table class="table table-bordered">
        <thead>
            <tr>
                <th>
                    {{ $t("invitations.inviter") }}
                </th>
                <th>
                    {{ $t("invitations.status") }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in data.income" :key="item.id">
                <td>
                    {{item.inviterUser.userDetail.surname}} 
                    {{item.inviterUser.userDetail.firstname}}
                    {{item.inviterUser.userDetail.patronname}}
                </td>
                <td>{{item.status == 0 ? translate('Не подтверждено','Расталмады'):
                    translate('Подтверждено','Расталды')}}
                </td>
            </tr>
        </tbody>
        </table>
        <paginate
            v-if="incomePageCount"
            v-model="incomePage"
            :page-count="incomePageCount"
            :click-handler="changeIncomePage"
            :prev-text="'&laquo;'"
            :next-text="'&raquo;'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
            ></paginate>

    </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
    data() {
        return {
            filter: {
                income: {
                    page: 0,
                    length: 5,
                    filter: {income: true}
                },
                outcome: {
                    page: 0,
                    length: 5,
                    filter: {outcome: true}
                },
            },
            totalPages: {
                income: 0,
                outcome: 0
            },
            data: {
                income: [],
                outcome: [],
            },
            form: {
                invitedEmail: null
            }
        };
    },
    validations: {
        form: {
            invitedEmail: { required, email }
        }
    },
    mounted() {
        this.onCreate();
    },
    methods: {
        onCreate() {
            this.getIncomeInvitations();
            this.getOutcomeInvitations();
        },
        translate(rus, kaz) {
            if (this.$i18n.locale === 'kz') {
                return kaz;
            }
            return rus;
        },
        getInvitations(payload) {
            this.$store.dispatch("LOAD_INVITATIONS", this.filter[payload])
                .then(s => {
                    this.data[payload] = s.content;
                    this.filter[payload].page = s.number;
                    this.totalPages[payload] = s.totalPages;
                })
                ;

        },
        getIncomeInvitations() {
            this.getInvitations('income');
        },
        getOutcomeInvitations() {
            this.getInvitations('outcome');
        },
        onSubmit() {
            this.$v.form.$touch();
            if (! this.$v.form.$invalid) {
                this.$store.dispatch("SAVE_INVITATION", this.form)
                .then(() => {
                    this.form = {};
                    this.getOutcomeInvitations();
                    this.$toast.success( this.$t("message.saved")); 
                })
                ;
            }
            else {
                this.$toast.error("not save");
            }
        },
        remove(id) {
            
            if (confirm("Вы действительно хотите удалить приглашение?")) {
                if (id) {
                    this.$store.dispatch("DELETE_INVITATION", id)
                    .then(() => {
                        this.outcomePage = 1;
                        this.getOutcomeInvitations();
                    });
                }
            }
        },
        changeOutcomePage(num) {
            this.outcomePage = num;
            this.getOutcomeInvitations();
        },
        changeIncomePage(num) {
            this.incomePage = num;
            this.getIncomeInvitations();
        }
    },
    computed: {
        incomePage: {
            get() {
                return this.filter.income.page + 1;
            },
            set(val) {
                this.filter.income.page = val - 1;
            }
        },
        outcomePage: {
            get() {
                return this.filter.outcome.page + 1;
            },
            set(val) {
                this.filter.outcome.page = val - 1;
            }
        },
        incomePageCount() {
            return this.totalPages['income'];
        },
        outcomePageCount() {
            return this.totalPages['outcome'];
        }
    }
}
</script>